import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import SQL from "../../Assets/Projects/SQL-Databases.png";
import Powerbi from "../../Assets/Projects/powerbi.png";
import kevina from "../../Assets/Projects/Kevina.png";
import Teacorp from "../../Assets/Projects/TEA.png";
import Scraping from "../../Assets/Projects/Web Scraping.png";
import fastworkblog from "../../Assets/Projects/fastworkblog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Teacorp}
              isBlog={false}
              title="TEA Corporation Co., Ltd."
              description="currently work as a marketer and specialize in data analysis. at TEA Corporation, where I have been for 4 years. in food and beverage industry."
              // ghLink="https://teacorp.co.th/"
              demoLink="https://teacorp.co.th/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fastworkblog}
              isBlog={false}
              title="Freelancer"
              description="freelancer on fastwork, focusing on data analysis and digital marketing.
              through this platform, I assist clients in optimizing their business performance by analyzing data and developing effective online marketing strategies."
              demoLink="https://fastwork.co/user/j4ck69"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kevina}
              isBlog={false}
              title="Kevina"
              description="I create targeted advertisements and detailed performance reports to help clients optimize their business efficiency and achieve sustainable growth through data-driven strategies."
              demoLink="https://www.facebook.com/kevinacollection/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SQL}
              isBlog={false}
              title="SQL Database"
              description="Proficient in SQL, with expertise in writing queries, managing databases, and optimizing performance. I use SQL to extract, analyze, and manipulate data efficiently,
              enabling accurate reporting and data-driven decision-making."
              demoLink="https://fastwork.co/user/j4ck69"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Scraping}
              isBlog={false}
              title="Web Scraping"
              description="web scraping, using tools like Python to extract and organize data from websites for purposes like market research, competitor analysis, and trend monitoring."
              demoLink="https://shopee.co.th/raumkadsan"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Powerbi}
              isBlog={false}
              title="PowerBi"
              description="expertise in creating Power BI reports to transform raw data into interactive dashboards and visualizations. My work involves connecting data sources, designing intuitive layouts, 
              and delivering insights that support decision-making and business strategy."
              demoLink="https://fastwork.co/user/j4ck69"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
