import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/me.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  // AiOutlineTwitter,
  // AiFillInstagram,
  AiFillWechat,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import FastworkLogo from "../../Assets/Projects/Fastworkicon.png"; 

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I fell in love with data analysis and Along the way
              something, I think… 🤷‍♂️
              <br />
              <br />data analysis helps with business decision-making.
              <i>
                <b className="purple"> I am proficient in SQL. </b>
              </i>
              <br />
              <br />
              I am exploring new topics and expanding my knowledge in emerging fields. &nbsp;
              <i>
                <b className="purple">Web Technologies, Python , Javascript  </b> and
                also in areas related to{" "}
                <b className="purple">
                  Data Analysis
                </b>
              </i>
              <br />
              <br />
              I enjoy solving problems and transforming data into actionable insights.
              My goal is to keep improving my skills and apply them to real-world challenges.
              with <b className="purple">SQL , Python </b> and
              <i>
                <b className="purple">
                  {" "}
                Data Visualization
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Thaksinai1996"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://fastwork.co/user/j4ck69"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <img
                  src={FastworkLogo}
                  alt="Fastwork"
                  style={{ width: "20px", height: "20px" }}
                  />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/thaksinai-kanarkat-260105277/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://line.me/ti/p/ZRUlK6eZTM"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillWechat />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
