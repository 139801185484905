import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiPython } from "react-icons/di";
import { 
  SiPostgresql,
  SiMysql,
  SiMicrosoftsqlserver,
  SiGoogleanalytics,
  SiMeta,
  SiTiktok,
} from "react-icons/si";
import { FaTag } from "react-icons/fa"; // สำหรับ GTM

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={12} md={4} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <SiMicrosoftsqlserver />
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <FaTag /> {/* Google Tag Manager */}
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <SiGoogleanalytics /> {/* Google Analytics */}
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <SiMeta /> {/* Google Analytics */}
      </Col>
      <Col xs={12} md={4} className="tech-icons">
        <SiTiktok /> {/* Google Analytics */}
      </Col>
    </Row>
  );
}

export default Techstack;
